(function ($) {
  const YOUTUBE_API_URL = 'https://www.youtube.com/iframe_api';

  Drupal.behaviors.yt_dynamic_player_v1 = {
    attach: function (context) {
      var queuedFn;

      initialize();

      function loadScript() {
        // yt_dynamic_player_v1 - V1.0
        // Call on the YouTube iFrame API.
        if (!$('script[src="' + YOUTUBE_API_URL + '"]', context).length) {
          var tag = document.createElement('script');

          tag.src = YOUTUBE_API_URL;
          var firstScriptTag = document.getElementsByTagName('script')[0];

          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

          return true;
        }

        return false;
      }
      function initialize() {
        // Behaviour Global vars.
        var ytPlayer;
        var ytPlayerTarget = $('#ytPlayerTarget')[0];
        var ytPlayerPauseBtn = $('.js-ytPlayer__btn-play');
        var ytPlayerCloseBtn = $('.js-ytPlayer__img-close');
        var ytPlayerContainer = $('.js-ytPlayer__container');
        var ytPlayerWrapper = $('.js-ytPlayer');
        var ytPlayerScrubber = $('.js-ytPlayer__scrubber-inner');
        var ytPlayerFullscreen = $('.js-ytPlayer__btn-full');
        var ytPlayerTrigger = $('.js-ytPlayer__icon, .js-ytPlayer__copy');
        var scrubberUpdate;
        var inlineHeightElem, inlineWidth, inlineHeight, theOffset;
        var heightConverted, widthConverted, theOffsetTop, theOffsetLeft, oneVW;
        var sitewideBannerV1Close = $('.js-close-site-banner');
        var controls, isMobile;

        if (!$('body').hasClass('device-mobile')) {
          controls = 0;
        } else {
          isMobile = true;
          controls = 1;
        }

        // Create our player.
        onYouTubeIframeAPIReady = function () {
          ytPlayer = new YT.Player(ytPlayerTarget, {
            width: '100%',
            playerVars: {
              'autohide': 1,
              'autoplay': 1,
              'cc_load_policy': 0,
              'controls': controls,
              'disablekb': 0,
              'end': 0,
              'wmode': 'transparent',
              'fs': 1,
              'loop': 0,
              'html5': 1,
              'playsinline': 1,
              'rel': 0
            },
            events: {
              'onReady': onPlayerReady,
              'onStateChange': onPlayerStateChange
            }
          });
        };

        // Closing the player.
        ytPlayerCloseBtn.on('click', function () {
          clearInterval(scrubberUpdate);
          scrubberUpdate = null;
          ytPlayer.pauseVideo();
          ytPlayerCloseBtn.addClass('hidden');
          ytPlayerWrapper.removeClass('playing').fadeOut(400, function () {
            ytPlayer.seekTo(0);
            ytPlayer.stopVideo();
            if (isMobile) {
              ytPlayerPauseBtn.removeClass('active');
            }
          });
        });

        // Open the player.
        function openThePlayerOverlay() {
          ytPlayerCloseBtn.removeClass('hidden');
          if (isMobile) {
            ytPlayerPauseBtn.addClass('active');
          }
          ytPlayerWrapper.fadeIn().addClass('playing');
          ytPlayerContainer.show();
          ytPlayerScrubber.css('width', '0');
        }

        // YOUTUBE IFRAME API.
        function onPlayerStateChange(event) {
          if (event.data === YT.PlayerState.PLAYING) {
            ytPlayerPauseBtn.addClass('active');
            // Scrubber
            if (!scrubberUpdate) {
              scrubberUpdate = window.setInterval(function () {
                var ytPlayerDuration = ytPlayer.getDuration();
                var ytPlayerCurrentTime = ytPlayer.getCurrentTime();

                ytPlayerScrubber.css({
                  'width': (ytPlayerCurrentTime / ytPlayerDuration) * 100 + '%'
                });
                if (ytPlayerCurrentTime >= (ytPlayerDuration - 0.1)) {
                  ytPlayerCloseBtn.trigger('click');
                }
              }, 5);
            }
          } else if (event.data === YT.PlayerState.PAUSED) {
            ytPlayerPauseBtn.removeClass('active');
          }
        }

        // Toggle between fullscreen.
        // Uses the attached 'Screenfull' library.
        function handleFullscreen() {
          if (screenfull.enabled) {
            ytPlayerFullscreen.on('click', function () {
              screenfull.request($('.js-ytPlayer__container')[0]);
            });
          } else {
            ytPlayerFullscreen.hide();
          }
        }

        // Attach play/pause events to our player controls.
        function playPauseFun() {
          ytPlayerPauseBtn.on('click', function () {
            if (ytPlayerPauseBtn.hasClass('active')) {
              ytPlayer.pauseVideo();
              ytPlayerPauseBtn.removeClass('active');
            } else {
              ytPlayerPauseBtn.addClass('active');
              ytPlayer.playVideo();
            }
          });
        }

        // Return vw values for the player dimensions on mobile.
        function calculateViewportVal(inlineHeight, inlineWidth, theOffset) {
          // Calculate the vw value of the px values to enable
          // responsive player embedding.
          oneVW = 100 / $(window).width();
          heightConverted = oneVW * inlineHeight;
          widthConverted = oneVW * inlineWidth;
          theOffsetLeft = oneVW * theOffset.left;
          theOffsetTop = oneVW * theOffset.top;

          return heightConverted, widthConverted, theOffsetLeft, theOffsetTop;
        }

        // If the video is playing and the sitewideBannerV1 is closed,
        // handle the document jump once the banner has finished
        // slideUp. Mobile only.
        sitewideBannerV1Close.on('click', function () {
          if (theOffset !== undefined) {
            var bannerDiff;

            if ($(window).width() <= 375) {
              bannerDiff = 70;
            } else if ($(window).width() > 375) {
              bannerDiff = 71;
            }
            var minusVal = oneVW * (theOffset.top - bannerDiff);

            setTimeout(function () {
              ytPlayerWrapper.css({
                'top': minusVal + 'vw'
              });
            }, 400);
          } else {
            return;
          }
        });

        // Apply the dimensions to the player if inline.
        function playerStyles(heightCalc, widthCalc, offsetLeftCalc, offsetTopCalc, unit) {
          ytPlayerWrapper.css({
            'height': heightCalc + unit,
            'width': widthCalc + unit,
            'left': offsetLeftCalc + unit,
            'top': offsetTopCalc + unit
          });
        }

        // Branch functionality depending on desktop/mobile inline player.
        function calculateVals() {
          calculateViewportVal(inlineHeight, inlineWidth, theOffset);
          playerStyles(heightConverted, widthConverted, theOffsetLeft, theOffsetTop, 'vw');
        }

        // Grab the dimensions from the module which has the data attribute.
        function theVals($this) {
          inlineHeightElem = $this.closest('[data-yt-inline-dimensions]');
          inlineHeight = inlineHeightElem.height();
          inlineWidth = inlineHeightElem.width();
          theOffset = inlineHeightElem.offset();
        }

        // Init the inline placement and handle browser changes.
        function manageInlinePlacement($this) {
          theVals($this);
          calculateVals();
          ytPlayerWrapper.addClass('ytPlayer__inline');
        }

        // Set up inline placement and clear styles previously set.
        function handleInline($this) {
          ytPlayerWrapper.removeClass('ytPlayer__inline');
          ytPlayerWrapper.css({
            'height': '',
            'width': '',
            'left': '',
            'top': ''
          });
          // Check if player being requested is inline.
          // Only go ahead with an inline player if the data value matches the
          // screen size.
          if ($this.hasClass('js-ytPlayer__inline')) {
            if ($(window).width() <= 767 && $this.closest('[data-yt-inline-dimensions]').data('yt-inline-dimensions') === 'mobile') {
              manageInlinePlacement($this);
            } else {
              return;
            }
          }
        }

        // YOUTUBE IFRAME API.
        // Load the video.
        function loadVideo(theSource) {
          queuedFn = function () {
            ytPlayer.loadVideoById(theSource);
          };
          if (!loadScript()) {
            queuedFn();
          }

          return false;
        }

        // Event triggered when our play icon/copy is clicked.
        function triggerVideoPlayback($elements) {
          if ($elements && $elements.length > 0) {
            ytPlayerTrigger = $elements;
          }
          ytPlayerTrigger.on('click', function () {
            var $this = $(this);
            var theSource = $this.attr('data-yt-dynamic-src');

            handleInline($this);
            clearInterval(scrubberUpdate);
            if (ytPlayerWrapper.hasClass('playing')) {
              ytPlayer.pauseVideo();
              ytPlayer.seekTo(0);
              ytPlayerScrubber.css('width', '0');
              scrubberUpdate = null;
              loadVideo(theSource);
            } else {
              openThePlayerOverlay();
              loadVideo(theSource);
            }
          });
        }

        // YOUTUBE IFRAME API.
        // Init functionality once the player is loaded and ready.
        function onPlayerReady() {
          if (queuedFn) {
            queuedFn();
          }
        }
        playPauseFun();
        handleFullscreen();
        triggerVideoPlayback();

        function applyEvents(e) {
          triggerVideoPlayback(e.payload);
        }
        $(document).on('yt_dynamic_player:apply_listeners', applyEvents);
      }
    }
  };
})(jQuery);
